/*
 * ============================
 * File: main.js
 * Project: Octavia-Admin
 * File Created: Thursday, 9th April 2020 2:11:05 am
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Tuesday, 14th April 2020 7:17:10 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */
import Vue from 'vue';
import App from './App.vue';
import excel from 'vue-excel-export'
import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
import "vue-croppa/dist/vue-croppa.css";
import Croppa from 'vue-croppa'
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins';
import octaviaKit from '@/plugins/octavia.kit';
import VueApexCharts from 'vue-apexcharts';
import i18n from '@/plugins/i18n';  // Import i18n instance
// mock
import './fake-db/index.js';

Vue.component('apexchart', VueApexCharts);

Vue.use(octaviaKit);
Vue.config.productionTip = false;
Vue.use(VueFileAgent);
Vue.use(Croppa) 
Vue.use(excel)


// Initialize Vue I18n for Vue 2
// Vue.use(VueI18n); // <-- Use VueI18n in Vue 2

// // Get the saved locale from local storage or default to 'en'
// const savedLocale = localStorage.getItem('locale') || 'en';

// // Create the i18n instance with the language messages
// const i18n = new VueI18n({
//   locale: 'en',  // Default language
//   fallbackLocale: 'en',  // Fallback if the chosen language is missing translations
//   messages: {
//     en,
//     'zh-CN': zhCN,  // Chinese Simplified
//     ms,  // Malaysia Malay
//   },
// });

// Save the selected locale to local storage whenever it changes
router.beforeEach((to, from, next) => {
  if (to.query.lang) {
    i18n.locale = to.query.lang;
    localStorage.setItem('locale', to.query.lang);  // Save the selected language to local storage
  }
  next();
});

new Vue({
  store,
  router,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
