import Vue from 'vue';
import VueI18n from 'vue-i18n';
// Import language files
import en from '@/lang/locales/en.json';
import zhCN from '@/lang/locales/zh-CN.json';
import ms from '@/lang/locales/ms.json';

Vue.use(VueI18n); // Initialize VueI18n

// Get the saved locale from local storage or default to 'en'
const savedLocale = localStorage.getItem('locale') || 'en';

// Create the i18n instance
const i18n = new VueI18n({
  locale: savedLocale,  // Use saved locale or default to 'en'
  fallbackLocale: 'en', // Fallback language
  messages: {
    en,
    'zh-CN': zhCN,
    ms,  // Malaysia Malay
  },
});


export default i18n;